<!-- 设备管理-维修管理-维修记录 -->
<template>
  <div class="page_container height_100">
    <div class="bgc_FFFFFF height_100">
      <div class="box_head1">
        <span>维修记录</span>
        <div class="flex align_center fn_size14 mg_left_50">
          <div>设备编号：</div>
          <el-input
            v-model="deviceID"
            placeholder="请输入设备编号"
            class="mg_left_8 width_150"
          ></el-input>
          <div class="mg_left_30">维修类型：</div>
          <el-select
            v-model="fTValue"
            placeholder="全部"
            class="width_150"
            clearable
          >
            <el-option
              v-for="(item, index) in faultType"
              :key="index"
              :label="item.description"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <div class="mg_left_30">评分等级：</div>
                    <el-select v-model="gradingArrValue" placeholder="请选择" class="mg_left_8 width_150" clearable>
                        <el-option v-for="item in gradingArr" :key="item.value" :label="item.description"
                            :value="item.value">
                        </el-option>
                    </el-select> -->
          <div class="mg_left_30">评分等级：</div>
          <el-select
            v-model="execGrade"
            placeholder="请选择"
            class="mg_left_8 width_150"
            clearable
          >
            <el-option
              v-for="item in execGradeArr"
              :key="item.value"
              :label="item.description"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="mg_left_30">维修组：</div>
          <el-select
            v-model="rGValue"
            placeholder="全部"
            class="mg_left_8 width_150"
            clearable
          >
            <el-option
              v-for="(item, index) in repairGroup"
              :key="index"
              :label="item.description"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="mg_left_30">维修时间：</div>
          <el-date-picker
            value-format="timestamp"
            v-model="repairTimeValue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <el-button
            type="primary"
            class="mg_left_10"
            size="medium"
            @click="query"
            >查询
          </el-button>
          <el-button
            type="primary"
            class="mg_left_10"
            @click="exportRepairRecord"
            size="medium"
            style="background: #009bfd"
            >导出</el-button
          >
        </div>
      </div>

      <!-- <div class="flex justify_between pdt_20 fn_size16">
                <div class="flex">
                    <el-button type="primary" class="width_120"
                        style=" background: #03CABE;border: none;" @click="markEmpirical">标识经验</el-button>
                   <el-button type="primary" class="width_120" style=" background: #FEA313;border: none;">派工
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #657798;border: none;">验证
                    </el-button>
                    <el-button type="primary" class="width_120" style=" background: #5781EA;border: none;">评分
                    </el-button> -->
      <!-- <el-button type="primary" icon="el-icon-delete" class="width_120 mg_left_10"
                        style=" background: #F75753;border: none;">删除</el-button> 
                </div>-->
      <!-- <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                </el-button> 
            </div>-->
      <!-- <div > -->
      <div class="pdl30 pdr30" style="height: 85%">
        <el-table
          ref="multipleTable"
          :data="recordData"
          tooltip-effect="dark"
          style="width: 100%"
          class="mg_top_20"
          @selection-change="handleSelectionChange"
          height="100%"
          id="repairPlanTable"
          @row-dblclick="dblclickGotoFaultDetails"
        >
          <el-table-column type="index" label="序号" width="80">
          </el-table-column>
          <el-table-column
            prop="orderID"
            label="工单编号"
            width=""
            show-overflow-tooltip
          >
          </el-table-column>
          <!-- <el-table-column prop="projectID" label="公司编号" width="">
                    </el-table-column> -->
          <el-table-column prop="deviceID" label="设备编号" width="">
          </el-table-column>
          <el-table-column prop="deviceName" label="设备名称" width="">
          </el-table-column>
          <!-- <el-table-column prop="deviceType" label="设备类型" width="">
                    </el-table-column> -->
          <!-- <el-table-column prop="deviceModel" label="设备型号" width="">
                    </el-table-column> -->
          <!-- <el-table-column prop="authSort" label="当前审批顺序" width="">
                    </el-table-column> -->

          <!-- <el-table-column label="是否审批" width="80">
                        <template slot-scope="scope">
                            <span>{{scope.row.isAuth}}</span>
                        </template>
                    </el-table-column> -->

          <!-- <el-table-column prop="deviceDepartment" label="设备所属部门" width="">
                    </el-table-column> -->
          <!-- <el-table-column prop="deviceLocation" label="设备位置" width="">
                    </el-table-column> -->
          <!-- <el-table-column prop="failureTime" label="故障时间" width="" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="oprPerson" label="记录人" width="">
                    </el-table-column>
                    <el-table-column prop="oprTime" label="记录时间" width="" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="failureCode" label="故障代码" width="">
                    </el-table-column> -->
          <el-table-column prop="failureDevPart" label="故障部位" width="">
          </el-table-column>
          <!-- <el-table-column prop="failureLevel" label="故障等级">
                    </el-table-column> -->
          <el-table-column prop="failureType" label="故障类型">
          </el-table-column>
          <!-- <el-table-column prop="failureDesc" label="故障描述">
                    </el-table-column>
                    <el-table-column prop="failureReason" label="故障原因">
                    </el-table-column> -->
          <!-- <el-table-column prop="execGroup" label="维修组">
                    </el-table-column>
                    <el-table-column prop="execPerson" label="维修人">
                    </el-table-column> -->
          <el-table-column prop="orderStatus_name" label="工单状态">
          </el-table-column>
          <!-- <el-table-column prop="execLevel" label="维修等级">
                    </el-table-column> -->
          <el-table-column prop="execType_name" label="维修类型">
          </el-table-column>
          <!-- <el-table-column prop="execEstimateLen" label="维修预估花费时间（分钟）">
                    </el-table-column> -->
          <el-table-column prop="urgenLevel_name" label="紧急程度">
          </el-table-column>

          <!-- <el-table-column label="是否停机" width="80">
                        <template slot-scope="scope">
                            <span>{{scope.row.machineStop}}</span>
                        </template>
                    </el-table-column> -->

          <!-- <el-table-column prop="stopLen" label="停机时长">
                    </el-table-column> -->
          <!-- <el-table-column prop="dispatchTime" label="派单时间" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="assignTakeTime" label="指定接单时间" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="isTakeOverdue" label="是否逾期结单">
                    </el-table-column> -->

          <!-- <el-table-column label="是否逾期接单" width="80">
                    <template slot-scope="scope">
                        <span>{{scope.row.isTakeOverdue}}</span>
                    </template>
                    </el-table-column> -->
          <!-- <el-table-column prop="takeTime" label="接单时间" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="startTime" label="维修开始时间" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="endTime" label="维修结束时间" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="isEndOverdue" label="是否逾期结单">
                    </el-table-column> -->

          <!-- <el-table-column label="是否逾期结单" width="80">
                    <template slot-scope="scope">
                        <span>{{scope.row.isEndOverdue}}</span>
                    </template>
                    </el-table-column> -->
          <!-- <el-table-column prop="assignEndTime" label="指定维修结单时间" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="pauseTimeList" label="暂离时间列表">
                    </el-table-column> -->
          <!-- <el-table-column prop="verifyTime" label="验证时间" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="verifyRemarks" label="验证备注">
                    </el-table-column> -->
          <!-- <el-table-column prop="execRejectStartTime" label="验证不通过维修开始时间" :formatter="formatDate">
                    </el-table-column>
                    <el-table-column prop="execRejectEndTime" label="验证不通过维修结束时间" :formatter="formatDate">
                    </el-table-column> -->
          <!-- <el-table-column prop="execLenM" label="维修时长（分钟）">
                    </el-table-column> -->
          <el-table-column prop="execFee" label="维修费用"> </el-table-column>
          <!-- <el-table-column prop="spotCondition" label="现场情况">
                    </el-table-column> -->
          <!-- <el-table-column prop="execDesc" label="维修描述">
                    </el-table-column> -->
          <!-- <el-table-column prop="execGrade" label="工单评分等级">
                    </el-table-column> -->
          <!-- <el-table-column label="是否为经验性记录" width="">
                    <template slot-scope="scope">
                        <span>{{scope.row.isEmpirical}}</span>
                    </template>
                    </el-table-column> -->
          <!-- <el-table-column label="是否委外" width="">
                    <template slot-scope="scope">
                        <span>{{scope.row.isOutEntrust}}</span>
                    </template>
                    </el-table-column> -->

          <el-table-column prop="remarks" label="备注" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="详细">
            <template slot-scope="scope">
              <el-button type="text" class="color_009BFD pd_0">
                <span
                  style="border-bottom: 1px solid #009bfd"
                  @click="gotoDetails(scope.row.ID)"
                  >详细</span
                >
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <!-- <el-button type="text" class="color_009BFD">
                                <span style="border-bottom: 1px solid #009BFD;" @click="gotoDetails(scope.row.ID)">详细</span>
                            </el-button> -->
              <el-button
                type="text"
                class="color_009BFD pd_0"
                v-if="!scope.row.isEmpirical"
              >
                <span
                  style="border-bottom: 1px solid #009bfd"
                  @click="markEmpirical(scope.row.ID)"
                  >标识经验</span
                >
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="flex justify_end">
          <el-pagination
            layout="prev, pager, next"
            :total="pageQuantity * pageSize"
            :page-size="pageSize"
            :current-page.sync="currentPage"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
      <!-- </div> -->

      <!-- <div class="tab_horizontal mg_top_24">
                <div class="tab_box mg_left_30 tab_box_active">故障图片</div>
            </div>
            <div class="mg_top_20 pdb_20 height_300">
                此处是故障图片 
            </div> -->
    </div>
  </div>
</template>

<script>
//   import innerTopNav from '../../../components/innerTopNav'
import {
  repairOrderRecordQueryLimit,
  repairGroupQuery,
  // faultTypeQuery,
  repairTypeQuery,
  repairOrderMarkEmpirical,
  repairOrderExecGradeQuery,
  //maintainOrderExecGradeQuery
} from "@/api/api_deviceManagement";
export default {
  // components: {
  //   innerTopNav
  // },
  data() {
    return {
      tabIndex: 0,
      value: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      multipleSelection: [],
      //table
      repairTimeValue: null,
      recordData: [],
      fTValue: null,
      rGValue: null,
      faultType: [],
      repairGroup: [],
      currentPage: 1,
      pageSize: 10,
      pageQuantity: 0,
      deviceID: "",
      gradingArrValue: "",
      gradingArr: [],
      execGrade: "",
      execGradeArr: [],
    };
  },
  mounted() {
    if (localStorage.getItem("userInfo") != null) {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.userID = userInfo.userID;
    }
    this.repairOrderRecordQueryLimit();
    this.repairGroupQuery();
    this.faultTypeQuery();
    //this.maintainOrderExecGradeQuery()
    this.repairOrderExecGradeQuery();
  },
  methods: {
    dblclickGotoFaultDetails() {},
    //导出数据
    exportRepairRecord() {
      this.$exportExcel.exportDataTwo("repairPlanTable", "维修记录");
    },

    async repairOrderRecordQueryLimit() {
      var param = {
        userID: this.userID,
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
      };
      if (this.deviceID) {
        param.deviceID = this.deviceID;
      }
      if (this.fTValue !== "" && this.fTValue != null) {
        param.execType = this.fTValue;
      }
      // if (this.gradingArrValue != '' && this.gradingArrValue != null) {
      //     param.execGrade = this.gradingArrValue
      // }
      if (this.execGrade != "" && this.execGrade != null) {
        param.execGrade = this.execGrade;
      }
      if (this.rGValue != "" && this.rGValue != null) {
        param.execGroup = this.rGValue;
      }
      if (this.repairTimeValue != null) {
        param.startExecTime = this.repairTimeValue[0];
        param.endExecTime = this.repairTimeValue[1];
      }
      const selectRes = await repairOrderRecordQueryLimit(param);
      console.log(selectRes);
      this.pageQuantity = selectRes.pageQuantity;
      this.recordData = selectRes.array;
    },

    //选中的行
    handleSelectionChange(val) {
      this.multipleSelection = val;
      //要批量删除的数组
      // this.deleteNum = []; //数组赋值前，需要清空一下数组
      this.deleteNum = "";
      // this.isAuth = '';
      for (var i = 0; i < val.length; i++) {
        this.deleteNum += val[i].ID + ",";
        // this.isAuth += val[i].userID + ",";
      }
      //去掉最后一个逗号
      if (this.deleteNum.length > 0) {
        this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
      }

      var delNum = this.deleteNum;
      window.localStorage.setItem("delNum", this.deleteNum);
      // var isAuth =this.isAuth;
      // window.localStorage.setItem('isAuth')
    },
    // gotoFastOrder() {
    //     this.$router.push({
    //         path: '/deviceManagement/maintenanceManagement/maintenanceOrder/fastOrder',
    //         // query:{setid:111222}
    //         // params:{setid:111222}//地址栏中看不到参数的内容
    //     })
    // },
    gotoDetails(id) {
      localStorage.setItem("recordID", id);
      this.$router.push({
        path: "/deviceManagement/maintenanceManagement/repairRecord/recordDetails",
        // query:{setid:111222}
        // params:{setid:111222}//地址栏中看不到参数的内容
      });
    },
    async repairGroupQuery() {
      var param = {
        userID: this.userID,
      }; //用户登录时
      const selectRes = await repairGroupQuery(param);
      this.repairGroup = selectRes.data;
      console.log("repairGroup", selectRes.data);
      //  this.gradingArr = selectRes.data
    },
    //评分等级
    async repairOrderExecGradeQuery() {
      var param = {
        userID: this.userID,
      }; //用户登录时
      const selectRes = await repairOrderExecGradeQuery(param);
      this.execGradeArr = selectRes.data;
      console.log("execGradeArr", selectRes.data);
      //  this.gradingArr = selectRes.data
    },

    async faultTypeQuery() {
      var param = {
        userID: this.userID,
      }; //用户登录时
      const selectRes = await repairTypeQuery(param);
      console.log("faultType", selectRes.data);
      this.faultType = selectRes.data;
    },
    // async maintainOrderExecGradeQuery() {
    //     var param = {
    //         userID: this.userID,
    //     } //用户登录时
    //     const selectRes = await maintainOrderExecGradeQuery(param);
    //     //this.gradingArr = selectRes.data;
    //     this.execGradeArr=selectRes.data
    // },
    markEmpirical(id) {
      //  if (this.multipleSelection.join(',') == '') {
      //     this.$message({
      //         message: '警告：请选择需要设置经验性的工单',
      //         type: 'warning'
      //     });
      //     return
      // }
      // if (this.multipleSelection.length > 1) {
      //     this.$message({
      //         message: '警告：请选择一条需要设置经验性的工单！！！',
      //         type: 'warning'
      //     });
      //     return
      // }
      this.$confirm("此操作为设置经验性工单操作, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.repairOrderMarkEmpirical(id);
          this.repairOrderRecordQueryLimit();
        })
        .catch(() => {});
    },
    async repairOrderMarkEmpirical(id) {
      var param = {
        ID: id,
      };
      const selectRes = await repairOrderMarkEmpirical(param);
      if (selectRes.data.result) {
        this.$message({
          message: selectRes.message,
          type: "success",
        });

        this.repairOrderRecordQueryLimit();
      } else {
        this.$message.error(selectRes.message);
      }
    },
    query() {
      this.currentPage = 1;
      this.repairOrderRecordQueryLimit();
    },
    handleCurrentChange(val) {
      this.PageNumber = val;
      this.repairOrderRecordQueryLimit(); //查询table数据的方法
    },
    formatDate(row, column) {
      // 获取单元格数据
      let data = row[column.property];
      if (data == undefined) {
        return "";
      }
      return this.tools.formatDate(data, "yyyy-MM-dd hh:mm:ss");
    },
  },
};
</script>

<style scoped>
.height_94 {
  height: 94%;
}
</style>
<style>
/* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

/* .el-button:focus,
    .el-button:hover {
    } */
</style>